import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import Layout from '../Layout/Layout';
import CompanyCertificationAdminPanel from './CompanyCertificationAdminPanel';
import ApplicationAdminPanel from './ApplicationAdminPanel';
import './adminStyles.css';
import AssessmentAdminPanel from './AssessmentAdminPanel';
import AssessorAdminPanel from './AssessorAdminPanel';
import Reports from './Reports';
import getAssessmentSurveyAssessments from '../../services/assessmentSurveyAssessment';
import { AssessmentSurveyAssessment } from '../../interfaces/assessmentSurveyAssessments.interface';
import { NotificationImportantRounded } from '@mui/icons-material';
import CertificationsAdminPanel from './CertificationsAdminPanel';
import UsersAdminPanel from './UsersAdminPanel';
import BillingRecordsAdminPanel from './BillingRecordsAdminPanel';
import SKUsAdminPanel from './SKUsAdminPanel';
import ApplicationTypesAdminPanel from './ApplicationTypeAdminPanel';
import SharedReportsAdminPanel from './SharedReportsAdminPanel';
import RemediationVerificationAdminPanel from './RemediationVerificationAdminPanel';
import { useAppSelector } from '../../app/hooks';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `admin-tabpanel-${index}`,
  };
}

export default function AdminTabs() {
  const [value, setValue] = React.useState(0);
  const [assessmentsAlert, setAssessmentsAlert] = React.useState(false);
  const { user } = useAppSelector(state => state.user);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    if (user?.type !== 'tpn_amdin') return;
    getAssessmentSurveyAssessments({ limit: 1 }).then((assessmentSurveyAssessments: AssessmentSurveyAssessment[]) => {
      if (assessmentSurveyAssessments.length > 0) setAssessmentsAlert(true);
    });
  }, []);

  const handleAssessmentsAddressed = () => setAssessmentsAlert(false);

  return (<>
    {user?.type ===  'tpn_admin' && <Layout pageTitle="Admins" includeHeader={false} includeDivider={false}>
      <Tabs variant="scrollable" value={value} onChange={handleChange} aria-label="Company Admin Area">
        <Tab label="Users" {...a11yProps(0)} />
        <Tab label="Company Certifications" {...a11yProps(1)} />
        <Tab label="Applications" {...a11yProps(2)} />
        <Tab sx={{ minHeight: '48px' }} icon={<>{assessmentsAlert && <NotificationImportantRounded color="warning" sx={{ position: 'absolute', top: '5px', left: '0', width: '.8em' }} />}</>}
          iconPosition="start"
          label='Assessments' {...a11yProps(3)}
        />
        <Tab label="Assessors" {...a11yProps(4)} />
        <Tab label="Reports" {...a11yProps(5)} />
        <Tab label="Certifications" {...a11yProps(6)} />
        <Tab label="Billing Records" {...a11yProps(7)} />
        <Tab label="SKUs" {...a11yProps(8)} />
        <Tab label="Application Types" {...a11yProps(9)} />
        <Tab label="Shared Reports" {...a11yProps(10)} />
        <Tab label="Remediation Verification" {...a11yProps(11)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <UsersAdminPanel />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CompanyCertificationAdminPanel />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ApplicationAdminPanel />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AssessmentAdminPanel assessmentsAddressed={() => handleAssessmentsAddressed() } />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <AssessorAdminPanel />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Reports />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <CertificationsAdminPanel />
      </TabPanel>
      <TabPanel index={value} value={7}>
        <BillingRecordsAdminPanel />
      </TabPanel>
      <TabPanel index={value} value={8}>
        <SKUsAdminPanel />
      </TabPanel>
      <TabPanel index={value} value={9}>
        <ApplicationTypesAdminPanel />
      </TabPanel>
      <TabPanel index={value} value={10}>
        <SharedReportsAdminPanel />
      </TabPanel>
      <TabPanel index={value} value={11}>
        <RemediationVerificationAdminPanel />
      </TabPanel>
    </Layout>}</>
  );
}
