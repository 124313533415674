import axios from 'axios';
import getAuthSession from './auth';
import { FetchAllWorkflowsRequest } from '../app/store/workflowSlice';
import { Workflow } from '../interfaces/workflow.interface';

export const wfHierarchy = [
  'incomplete',
  'non-compliant',
  'complete',
  'submittedForAssessment',
  'Assigned',
  'Pre-Assessment',
  'Assessing',
  'Submitted',
  'Remediation',
  'Complete',
];

export async function createNewWorkflow(props: { workflowType: string, baseline: number, serviceProvider: number, status: string, site?: number, application?: number, applicationVersion?: number, version?: number }) {
  const authSession = await getAuthSession();
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_API}/workflows/`,
    { ...props, isActive: true },
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data;
}

export async function fetchWorkflows(request: FetchAllWorkflowsRequest, isActive?: boolean) {
  const authSession = await getAuthSession();
  const active = typeof isActive === 'undefined' ? 'true' : isActive;
  const queryString = Object.entries(request).filter(([_, val]) => val !== undefined && (_ || !_)).map(([key, value]) => `${key}=${value}`).join('&');
  const url = `${process.env.REACT_APP_BASE_API}/workflows/?is_active=${active}${queryString ? `&${queryString}` : ''}`;
  const response = await axios.get(
    url,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data.results as Workflow[];
}

export async function updateWorkflow(request: Partial<Workflow>) {
  const authSession = await getAuthSession();
  const { id,  ...req } = request;

  const currentWorkflow = await axios.get(
    `${process.env.REACT_APP_BASE_API}/workflows/${id}/`,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  
  const currentStatus = currentWorkflow.data?.status;

  const currentStatusIndex = wfHierarchy.indexOf(currentStatus || '');
  const newStatusIndex = wfHierarchy.indexOf(req.status || '');
  const statusExceptions = (currentStatus === 'complete' && req.status === 'incomplete') || (currentStatus === 'non-compliant' && req.status === 'incomplete');
  // If the new status is lower in the hierarchy, just use the current status unless in pre-assessment phase
  if (newStatusIndex < currentStatusIndex && !statusExceptions) {
    req.status = currentStatus;
  }
  const url = `${process.env.REACT_APP_BASE_API}/workflows/${id}/`;
  const response = await axios.patch(
    url, req,
    {
      headers: { 'Authorization': `Bearer ${authSession.getIdToken().getJwtToken()}` },
    },
  );
  return response.data as Workflow;
}

export const workflowLabels = (wf:string) => {
  switch (wf) {
    case 'tpn_best_practices_5.1':
      return 'Questionnaire v5.1';
    case 'tpn_best_practices_5.2':
      return 'Questionnaire v5.2';
    case 'tpn_best_practices_5.3':
      return 'Questionnaire v5.3';
    default:
      return wf;
  }
};