import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Button, Card, CircularProgress, Select, Stack, FormControl, InputLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { Questionnaire, RemediationValidationRow } from '../../interfaces/questionnaire.interface';
import { getRemediationValidationAdminData } from '../../services/assessments';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchAll } from '../../app/store/questionnaireSlice';
import Moment from 'moment/moment';


// Function to return colors based on status
const getStatusColor = (status: string) => {
  switch (status) {
    case 'pass':
      return 'rgba(144, 238, 144, 0.6)';
    case 'fail':
      return 'rgba(255, 0, 0, 0.4)';
    case 'not_started':
      return 'rgba(211, 211, 211, 0.3)';
    default:
      return 'rgba(173, 216, 230, 0.6)';
  }
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  );
}

export default function RemediationVerificationAdminPanel() {
  const dispatch = useAppDispatch();

  const [rows, setRows] = useState<RemediationValidationRow[]>([]);
  const [loading, setLoading] = useState(true);
  const questionnaires = useAppSelector(state => state.questionnaires.results);
  const [questionniare, setQuestionnaire] = useState<Questionnaire | null>(null);

  React.useEffect(() => {
    dispatch(fetchAll({ type: 'mpa_best_practice' }));
  }, []);

  const handleActionClick = (rowData: any) => {
    const url = `/assessment/${rowData.annotatedAssessmentId}/questionnaire/${rowData.annotatedBpSurveyId}`;
    window.open(url, '_blank');
  };

  // Column definitions
  const columns: GridColDef[] = [
    { field: 'questionnaireTitle', headerName: 'Questionnaire', width: 200 },
    { field: 'companyName', headerName: 'Company', width: 200 },
    { field: 'siteOrAppName', headerName: 'Site/Application', width: 200 },
    {
      field: 'needsList',
      headerName: 'Question Code(s)',
      width: 450,
      filterable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', margin: '8px 0' }}>
          {Array.isArray(params.value) && params.value.map((item: { code: string, status: string }, idx: number) => (
            <span
              key={idx}
              style={{
                padding: '5px 10px',
                marginRight: '5px',
                marginBottom: '5px',
                fontWeight: 500,
                backgroundColor: getStatusColor(item.status),
                borderRadius: '4px',
                color: '#000',
                display: 'inline-block',
              }}
            >
              {item.code}
            </span>
          ))}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      filterable: false,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => handleActionClick(params.row)}
        >
          Verify Status
        </Button>
      ),
    },
    { field: 'assessmentDate', headerName: 'Assessment Date', width: 150, valueGetter: (params) => Moment(params.row.assessmentDate).format('MM/DD/YYYY') },
    { field: 'annotatedUpdatedAt', headerName: 'Last Updated', width: 150, valueGetter: (params) => Moment(params.row.annotatedUpdatedAt).format('MM/DD/YYYY') },
  ];

  const handleFetchVerificationData = React.useCallback(async () => {
    setLoading(true);
    const data = await getRemediationValidationAdminData(questionniare?.id) as RemediationValidationRow[];
  
    const formattedData: Record<string, RemediationValidationRow[]> = (data || []).reduce((acc, item) => {
      if (acc[item.annotatedAssessmentSurveyId]) {
        acc[item.annotatedAssessmentSurveyId].push(item);
      } else {
        acc[item.annotatedAssessmentSurveyId] = [item];
      }
      return acc;
    }, {} as Record<string, RemediationValidationRow[]>);
  
    const newRows = Object.keys(formattedData).map((key) => {
      const firstItem = formattedData[key][0];
      firstItem.needsList = formattedData[key].map((item: RemediationValidationRow) => ({
        code: item.questionShortCode,
        status: item.subRemediationVerificationStatus || item.assessorAnswerVerificationStatus,
      }));

      const {
        annotatedAssessmentSurveyId,
        questionnaireTitle,
        siteOrAppName,
        companyName,
        needsList,
        assessmentDate,
        annotatedUpdatedAt,
        annotatedAssessmentId,
        annotatedBpSurveyId,
        annotatedVendorStatus,
        assessorAnswerVerificationDate,
        assessorAnswerVerificationStatus,
        questionShortCode,
        remediationAnswerId,
        subRemediationDate,
        subRemediationPlan,
        subRemediationVerificationDate,
        subRemediationVerificationStatus,
      } = firstItem;
  
      return {
        id: annotatedAssessmentSurveyId,
        siteOrAppName,
        questionnaireTitle,
        companyName,
        annotatedAssessmentSurveyId,
        needsList: needsList || [],
        assessmentDate: assessmentDate ? new Date(assessmentDate).toLocaleDateString('en-US') : '',
        annotatedUpdatedAt: annotatedUpdatedAt ? new Date(annotatedUpdatedAt).toLocaleString('en-US') : '',
        annotatedAssessmentId,
        annotatedBpSurveyId,
        annotatedVendorStatus,
        assessorAnswerVerificationDate,
        assessorAnswerVerificationStatus,
        questionShortCode,
        remediationAnswerId,
        subRemediationDate,
        subRemediationPlan,
        subRemediationVerificationDate,
        subRemediationVerificationStatus,
      };
    });
  
    setRows(newRows);
    setLoading(false);
  }, [questionniare]);
  
  // Run fetch on mount
  useEffect(() => {
    handleFetchVerificationData();
  }, []);

  const handleQuestionnaireChange = React.useCallback((e: SelectChangeEvent<string | number>) => {
    setQuestionnaire(questionnaires.find((q) => q.id === e.target.value) || null);
  }, [questionnaires]);
  
  useEffect(() => {
    handleFetchVerificationData();
  }, [questionniare]);

  return (
    <Card sx={{ padding: '2em' }}>
      <Stack direction="row-reverse" spacing={1} mb={2}>
        <FormControl size="small" sx={{ width: 305 }}>
          <InputLabel id="questionnaire-select-label">Questionnaire</InputLabel>
          <Select
            labelId="questionnaire-select-label"
            id="questionnaire-select"
            value={questionniare ? questionniare.id : ''}
            onChange={handleQuestionnaireChange}
            label="Questionnaire"
            size="small"
            displayEmpty
          >
            {questionnaires.length > 0 ? (
              questionnaires.map((q) => (
                <MenuItem key={q.id} value={q.id}>
                  {q.title} {q.version}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="" disabled>
                No Questionnaires Available
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Stack>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <DataGrid
          style={{ height: 400, width: '100%' }}
          rows={rows}
          columns={columns}
          pageSize={25}
          rowsPerPageOptions={[25]}
          getRowId={(row) => row.id}
          components={{
            Toolbar: CustomToolbar,
          }}
          getRowHeight={() => 'auto'}
        />
      )}
    </Card>
  );
}
