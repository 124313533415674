import React, { useState, useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ErrorIcon from '@mui/icons-material/Error';
import StandardDialog from '../../Modals/StandardDialog';
import { StandardDialogActions } from '../../Modals/StandardDialog';
import { FormControl, InputLabel, MenuItem, Select, Box, Typography, Button, TextField } from '@mui/material';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { REMEDIATION_OPTIONS, REMEDIATION_VERIFICATION_STATUS } from '../../../services/surveyHelpers';
import { useAppSelector } from '../../../app/hooks';

const commonBoxStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px',
  paddingLeft: '16px',
  borderRadius: '4px',
  height: '50px',
  mt: 1,
  mb: 3,
};

const commonTypographyStyles = (theme: any) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 400,
  flexGrow: 1,
  color: theme.palette.customThemeColors?.darkGrey,
});

const commonButtonStyles = {
  ml: 2,
};

export default function RemediationValidation({
  origRemediationStatus,
  origVerificationStatus,
  origVerificationDate,
  origVerificationComment,
  isSubRemediation,
  patchRemediationValidationStatus,
}: {
  origRemediationStatus?: string,
  origVerificationStatus?: string,
  origVerificationDate?: string,
  isSubRemediation?: boolean,
  origVerificationComment?: string,
  patchRemediationValidationStatus: (status: string, isSubRemediation: boolean, comment: string) => void
}) {
  const theme = useTheme();
  const { user } = useAppSelector((state) => state.user);
  const [showVerifyMod, setShowVerifyMod] = useState(false);
  const [validationStatus, setValidationStatus] = useState(origVerificationStatus);
  const [comment, setComment] = useState(origVerificationComment || '');
  const formattedDate = origVerificationDate ? moment(origVerificationDate).format('MMMM D, YYYY') : 'Date not available';

  const handleSaveClick = () => {
    patchRemediationValidationStatus(validationStatus || '', isSubRemediation || false, comment || '');
    setShowVerifyMod(false);
  };

  useEffect(() => {
    setComment(origVerificationComment || '');
  }, [origVerificationComment]);
  
  return (
    <>
      {(!origVerificationStatus ||
        (!isSubRemediation && origRemediationStatus !== REMEDIATION_OPTIONS.remediated) ||
        (isSubRemediation && (!origRemediationStatus || origRemediationStatus !==  REMEDIATION_OPTIONS.remediated))
      ) && (
        <Box
          sx={{
            ...commonBoxStyles,
            backgroundColor: '#F1F4FA',
            color: 'black',
          }}
        >
          <Typography sx={commonTypographyStyles(theme)}>
          Remediated items in this Best Practice are subject to TPN Admin verification.
          </Typography>
        </Box>
      )}
      {origVerificationStatus === REMEDIATION_VERIFICATION_STATUS.pass && (
        <Box
          sx={{
            ...commonBoxStyles,
            backgroundColor: 'rgba(76, 175, 80, 0.07)',
          }}
        >
          <Typography sx={commonTypographyStyles(theme)}>
            <CheckCircleIcon sx={{ fontSize: '16px', mr: 1 }} color="success" />
            TPN Admin Verified - {formattedDate}
          </Typography>
            <Button variant="contained" color="primary" onClick={() => setShowVerifyMod(true)} sx={commonButtonStyles}>
            {user?.type === 'tpn_admin' ? 'Verify' : 'View Details' }
            </Button>
        </Box>
      )}
      {origVerificationStatus === REMEDIATION_VERIFICATION_STATUS.fail && (
        <Box
          sx={{
            ...commonBoxStyles,
            backgroundColor: 'rgba(244, 67, 54, 0.1)',
          }}
        >
          <Typography sx={commonTypographyStyles(theme)}>
            <ErrorIcon sx={{ fontSize: '16px', mr: 1 }} color="error" />
            Rejected By TPN Admin - {formattedDate}
          </Typography>
            <Button variant="contained" color="primary" onClick={() => setShowVerifyMod(true)} sx={commonButtonStyles}>
              {user?.type === 'tpn_admin' ? 'Verify' : 'View Details' }
            </Button>
        </Box>
      )}
      {origVerificationStatus === REMEDIATION_VERIFICATION_STATUS.notStarted && origRemediationStatus === REMEDIATION_OPTIONS.remediated && (
        <Box
          sx={{
            ...commonBoxStyles,
            backgroundColor: 'rgba(255, 193, 7, 0.1)',
          }}
        >
          <Typography sx={commonTypographyStyles(theme)}>
            <AccessTimeIcon sx={{ fontSize: '16px', mr: 1 }} color="warning" />
            Pending TPN Admin Verification
          </Typography>
            <Button variant="contained" color="primary" onClick={() => setShowVerifyMod(true)} sx={commonButtonStyles}>
            {user?.type === 'tpn_admin' ? 'Verify' : 'View Details' }
            </Button>
        </Box>
      )}
      <StandardDialog
        maxWidth="sm"
        subtitle={user?.type === 'tpn_admin' ? 'I have confirmed that this remediation adheres to our Best Practice Standards, in alignment with Zero-Trust Guidelines.' : ''}
        title="Remediation Verification"
        handleClose={() => setShowVerifyMod(false)}
        isOpen={showVerifyMod}
      >
        <Box sx={{ my: 2 }}>
          <FormControl fullWidth>
            <InputLabel id="validation-status-label">Verification Status</InputLabel>
            <Select
              labelId="validation-status-label"
              id="remediation-validation-status-select"
              value={validationStatus}
              disabled={user?.type !== 'tpn_admin'}
              onChange={(e) => setValidationStatus(e.target.value)}
              label="Validation Status"
            >
              <MenuItem value={REMEDIATION_VERIFICATION_STATUS.notStarted}>Not Started</MenuItem>
              <MenuItem value={REMEDIATION_VERIFICATION_STATUS.pass}>Pass</MenuItem>
              <MenuItem value={REMEDIATION_VERIFICATION_STATUS.fail}>Failed</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <TextField
          label="Comment"
          multiline
          rows={4}
          value={comment}
          disabled={user?.type !== 'tpn_admin'}
          onChange={(e) => setComment(e.target.value)}
          fullWidth
          sx={{ marginTop: 2 }}
        />
        { user?.type === 'tpn_admin' && (
        <StandardDialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button variant="outlined" onClick={() => setShowVerifyMod(false)}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSaveClick}>
              Save
            </Button>
          </Box>
        </StandardDialogActions>
        )}
      </StandardDialog>
    </>
  );
}
