// react and external-libs
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
// MUI
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Switch,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { AccessTimeOutlined, Add, Cancel, CheckCircle, Close, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
// redux
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { QuestionnaireType, fetchPublishedQuestionnaire } from '../../../../../app/store/surveySlice';
// interface
import { Questionnaire } from '../../../../../interfaces/questionnaire.interface';
import { Survey } from '../../../../../interfaces/survey.interface';
import { Workflow } from '../../../../../interfaces/workflow.interface';
import { AssessmentSurvey } from '../../../../../interfaces/assessment.interface';
import User, {
  Application,
  ApplicationVersion,
  Badge,
  Company,
  CompanyApplication,
  CompanyCertification,
  Version,
} from '../../../../../interfaces/users.interface';
import { fetchAll } from '../../../../../app/store/questionnaireSlice';
import { canStartFiveThreeSurvey } from '../../../../../services/adminUtils';
// service
import { createNewWorkflow, fetchWorkflows, workflowLabels } from '../../../../../services/workflow';
import { fetchGoldBadgeLabel, fetchBlueBadgeLabel, recentBadgeByType } from '../../../../../services/badge';
import createSurvey, { fetchSurvey } from '../../../../../services/surveys';
import { fetchAssessmentSurvey, updateAssessmentSurvey } from '../../../../../services/assessments';
// components
import GetBadge from '../../../GetBadge';
import WorkflowStatus from '../../../WorkflowStatus';
import WorkflowButtons from '../../../WorkflowButtons';
import AssessmentForm from '../../../../Assessments/NewAssessmentModal';
import { updateWorkflow } from '../../../../../app/store/workflowSlice';
import StandardDialog, { StandardDialogActions } from '../../../../Modals/StandardDialog';
import { HardeningDocument } from '../../../../../interfaces/hardeningDocuments';
import { QuestionnaireTypes } from '../../../../../services/questionnaires';

type VersionSidebarProps = {
  isOpen: boolean;
  company: Company;
  companyApp?: CompanyApplication;
  application?: Application;
  version?: Version;
  isAssessment?: boolean;
  onClose: () => void;
};

const VersionSidebar = (props: VersionSidebarProps) => {
  const { isOpen, company, companyApp, application, version, isAssessment = false, onClose } = props;
  // redux and router
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const questionnaires: Questionnaire[] = useAppSelector(state => state.questionnaires.results);
  const { applicationVersions: avs } = useAppSelector((state) => state.serviceCategories);
  const { application_baseline: appBaseline } = useAppSelector((state) => state.surveys);
  const { workflows: fullWf } = useAppSelector((state) => state.workflow);
  const { documents } = useAppSelector((state) => state.serviceCategories);
  // states
  const [isCreatingWorkflow, setIsCreatingWorkflow] = useState(false);
  const [workflowMenuAnchorEl, setWorkflowMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isAssessmentModalOpen, setIsAssessmentModalOpen] = useState(false);
  const [surveys, setSurveys] = useState<Survey[] | undefined>(undefined);
  const [assessmentSurveys, setAssessmentSurveys] = useState<AssessmentSurvey[] | undefined>(undefined);
  const [wfToDeactivate, setwfToDeactivate] = useState<undefined | Workflow>(undefined);
  const [inactiveWorkflows, setInactiveWorkflows] = useState<Workflow[]>([]);
  // local variables
  const canStartFiveDotThree = useMemo(() => canStartFiveThreeSurvey(user), [user]);
  const workflows = useMemo(
    () =>
      fullWf?.filter(
        (wf) =>
          (wf.application as Application)?.id === application?.id &&
          (wf.version as Version)?.id === version?.id &&
          wf.isActive,
      ),
    [fullWf, version, application],
  );
  const isWorkflowMenuOpen = Boolean(workflowMenuAnchorEl);
  const wfList = useMemo(() => {
    if (workflows) return [...workflows, ...inactiveWorkflows];
    return [...inactiveWorkflows];
  }, [workflows, inactiveWorkflows]);
  const siteNames = useMemo(() => companyApp?.sites?.map((site) => site.name), [companyApp?.sites]);
  const serviceNames = useMemo(() => companyApp?.services?.map((service) => service.name), [companyApp?.services]);
  const docs = useMemo(() => {
    if (!application?.hardeningDocuments?.length || !version) return [];
    const curDocs = (application.hardeningDocuments as HardeningDocument[]).filter(
      (hd) => hd.version === version.value,
    );
    if (!curDocs) return [];
    return curDocs;
  }, [application?.hardeningDocuments, version?.value]);

  const drawerStyles = useMemo(
    () => (theme: Theme) => ({
      '& .MuiPaper-root': {
        width: 600,
        backgroundColor: '#F3F5FB',
        [theme.breakpoints.down('sm')]: {
          width: 250,
        },
      },
    }),
    [],
  );
  // short/simple functions
  const handleWorkflowMenuClose = useCallback(() => setWorkflowMenuAnchorEl(null), []);

  const handleWorkflowClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    setWorkflowMenuAnchorEl(e.currentTarget);
  }, []);

  const handleCreateWorkflow = useCallback(
    async (type: string) => {
      try {
        setIsCreatingWorkflow(true);
        setWorkflowMenuAnchorEl(null);
        let finalBaseline = appBaseline;
        if (!finalBaseline) {
          const res = await dispatch(fetchPublishedQuestionnaire(QuestionnaireTypes.APPLICATION_BASELINE)).unwrap();
          finalBaseline = (res as QuestionnaireType).questionnaire.id;
        }

        if (canStartFiveDotThree) {
          finalBaseline = questionnaires.filter(q => q.type === 'application_baseline').sort((a, b) =>  b.id - a.id)[0].id;
        }

        const baselineSurvey = await createSurvey({
          questionnaire: finalBaseline as number,
          status: 'incomplete',
          company: company.id!,
          questionAnswers: [],
          application: application?.id,
          version: version?.value!,
        });
        if (!baselineSurvey) return;

        await createNewWorkflow({
          workflowType: type,
          baseline: baselineSurvey.id,
          status: 'incomplete',
          serviceProvider: company.id!,
          application: application?.id,
          version: version?.id,
        });
        navigate(`/questionnaire/${baselineSurvey.id!}/`, { replace: true });
      } catch (error) {
        console.log('creating workflow failed', error);
      } finally {
        setIsCreatingWorkflow(false);
      }
    },
    [appBaseline, company.id, application?.id, version, dispatch, navigate, questionnaires, canStartFiveDotThree],
  );

  const companyCerts = useMemo(() => {
    if (!avs.length || !company.companyCertifications || company.companyCertifications.length === 0 || !isOpen)
      return undefined;
    return company.companyCertifications.filter(
      (cc) =>
        cc.applicationVersions &&
        cc.applicationVersions.some((av) => {
          const CURAVS = avs.find((cav) => cav.id === av);
          if (!CURAVS) return;
          return (CURAVS.version as Version).id === version?.id;
        }),
    );
  }, [avs, company.companyCertifications, version]);

  const applicationVersions = useMemo(
    () => (companyCertification: CompanyCertification) => {
      if (
        !avs.length ||
        !companyCertification.applicationVersions ||
        companyCertification.applicationVersions.length === 0
      )
        return undefined;
      const newAVS = avs.filter(
        (av) => companyCertification.applicationVersions!.findIndex((ccav) => ccav === av.id) > -1,
      );
      if (!newAVS?.length) return undefined;
      return [...new Set(newAVS)];
    },
    [avs],
  );

  // Three months before expiration user can add new workflow ie start new questionnaire
  const canAddNewWorkflow = useMemo(
    () => (type: string) => {
      const wfs = workflows?.filter((wf) => wf.workflowType === type);
      if (wfs?.length === 0) return true;

      let badges: Badge[] = [];
      wfs?.forEach((wf) => {
        badges = badges.concat(wf.badges as Badge[]);
      });
      badges = badges
        .filter((b) => b.title === 'tpn_self_reported')
        .sort((a, b) => moment(b.expirationDate).unix() - moment(a.expirationDate).unix());

      if (badges.length === 0) return false;
      if (moment(badges[0].expirationDate).add(-3, 'months').unix() < moment().unix()) return true;
      return false;
    },
    [workflows],
  );

  const findSurvey = useMemo(
    () => (workflow: Workflow) =>
      surveys?.find((sur) => (workflow.bpSurvey ? sur.id === workflow.bpSurvey : sur.id === workflow.baseline)),
    [surveys],
  );

  const findAssessmentSurvey = useMemo(
    () => (workflow: Workflow) => assessmentSurveys?.find((as) => as.id === workflow.assessmentSurvey),
    [assessmentSurveys],
  );

  const handleFetchSurvey = useCallback(async (surveyId: number) => {
    const sur = await fetchSurvey(surveyId);
    setSurveys((cur) => {
      if (cur) return [...cur, sur];
      if (!cur) return [sur];
    });
  }, []);

  const handleFetchAssessmentSurvey = useCallback(async (assessmentSurveyId: number) => {
    const assSurvey = await fetchAssessmentSurvey(assessmentSurveyId);
    setAssessmentSurveys((cur) => {
      if (cur) return [...cur, assSurvey];
      if (!cur) return [assSurvey];
    });
  }, []);

  const deactivateWorkflow = () => {
    if (!wfToDeactivate) return;
    dispatch(updateWorkflow({ id: wfToDeactivate.id, isActive: false }));
    setwfToDeactivate(undefined);
  };

  const canDeactivate = (wf: Workflow) => user?.type === 'tpn_admin' && wf.isActive;
  const updateAssSur = (p: { assSur?:AssessmentSurvey, key:string, value: boolean }) => p.assSur && updateAssessmentSurvey({ ...p.assSur, [p.key]: p.value });

  const handleDocClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, id?: number) => {
      e.stopPropagation();
      if (!id) return;
      const doc = documents.find((cdoc) => cdoc.id === id);
      if (!doc) return;

      const link = document.createElement('a');
      link.setAttribute('href', doc.document);
      link.setAttribute('target', '_blank');
      link.click();
    },
    [documents],
  );

  useEffect(() => {
    dispatch(fetchAll({ unpublished: false }));
  }, []);

  useEffect(() => {
    if (!wfList.length) return;
    wfList.forEach((workflow) => {
      // if I have an assessment and status is in remediation, load survey
      if (workflow?.assessmentSurvey && workflow?.status !== 'Complete') {
        // fetch assessment survey
        if (!assessmentSurveys?.find((sas) => sas.id === workflow?.assessmentSurvey)) {
          handleFetchAssessmentSurvey(workflow?.assessmentSurvey);
        }
        return;
      }

      // we have a bp survey and no assessment
      if (
        workflow?.bpSurvey &&
        !workflow?.assessment &&
        !['submittedForAssessment', 'complete'].includes(workflow?.status)
      ) {
        // fetch survey
        if (!surveys?.find((ss) => ss.id === workflow?.bpSurvey)) {
          handleFetchSurvey(workflow?.bpSurvey);
        }
        return;
      }
    });
  }, [wfList]);

  useEffect(() => {
    if (!version) return;
    setInactiveWorkflows([]);
    fetchWorkflows(
      {
        application: application?.id,
        version: version?.id,
        service_provider: company.id,
      },
      false,
    ).then(setInactiveWorkflows);
  }, [version]);

  const mostRecentBlue = useMemo(() => workflows && recentBadgeByType(workflows, 'tpn_self_reported'), [workflows]);
  const mostRecentGold = useMemo(() => workflows && recentBadgeByType(workflows, 'tpn_assessed'), [workflows]);

  const renderMenuItem = useCallback(
    (surveyVersion: string) => {
      return canAddNewWorkflow(surveyVersion) ? (
        <MenuItem onClick={() => handleCreateWorkflow(surveyVersion)}>
          {workflowLabels(surveyVersion)}
        </MenuItem>
      ) : (
        <MenuItem disabled>No new Questionnaires</MenuItem>
      );
    },
    [canAddNewWorkflow, handleCreateWorkflow, workflowLabels],
  );

  return (
    <>
      <Drawer anchor='right' open={isOpen} onClose={onClose} sx={drawerStyles} keepMounted={false}>
        <Box
          component='section'
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: '1.25rem',
            bgcolor: '#2F2D4F',
          }}
        >
          <Typography fontSize='1.063rem' color='#fff' fontWeight='500'>
            App Version Details
          </Typography>
          <IconButton sx={{ color: '#fff' }} onClick={onClose}>
            <Close sx={{ fontSize: '1.125rem' }} />
          </IconButton>
        </Box>
        <Box component='section' sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.25rem' }}>
              <Typography sx={{ color: '#575757', fontWeight: 600 }}>{application?.name}</Typography>-
              <Typography sx={{ color: '#575757' }}>{version?.value}</Typography>
            </Box>
            <GetBadge
              isActive={company.isPublished}
              goldBadgeLabel={mostRecentGold?.badge && fetchGoldBadgeLabel([mostRecentGold.badge], mostRecentGold.version)}
              blueBadgeLabel={mostRecentBlue?.badge && fetchBlueBadgeLabel([mostRecentBlue.badge], mostRecentBlue.version)}
              certification={companyCerts?.at(-1)}
              company={company.id}
            />
          </Box>
        </Box>
        <Box
          component='section'
          sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem', borderBottom: '1px solid #DBE3EF' }}
        >
          <Typography sx={{ color: '#575757', fontWeight: 500 }}>Sites</Typography>
          <Box sx={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap', mt: '0.5rem' }}>
            {siteNames?.map((site, idx) => {
              return (
                <Chip
                  key={idx}
                  label={site}
                  size='small'
                  sx={(theme) => ({
                    bgcolor: theme.palette.chips?.site,
                    color: theme.palette.chips?.contrastText,
                    '& .MuiChip-label': { px: '1rem' },
                  })}
                />
              );
            })}
            {siteNames?.length === 0 && <Typography>-</Typography>}
          </Box>
        </Box>
        <Box
          component='section'
          sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem', borderBottom: '1px solid #DBE3EF' }}
        >
          <Typography sx={{ color: '#575757', fontWeight: 500 }}>Services</Typography>
          <Box sx={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap', mt: '0.5rem' }}>
            {serviceNames?.map((service, idx) => {
              return (
                <Chip
                  key={idx}
                  label={service}
                  size='small'
                  sx={(theme) => ({
                    bgcolor: theme.palette.chips?.service,
                    color: theme.palette.chips?.contrastText,
                    '& .MuiChip-label': { px: '1rem' },
                  })}
                />
              );
            })}
            {serviceNames?.length === 0 && <Typography>-</Typography>}
          </Box>
        </Box>
        <Box
          component='section'
          sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem', borderBottom: '1px solid #DBE3EF' }}
        >
          <Typography sx={{ color: '#575757', fontWeight: 500 }}>Hardening Guidelines</Typography>
          <Box sx={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap', mt: '0.5rem' }}>
            {docs.map((cd) => (
              <Chip
                key={cd.id}
                label={cd.type}
                size='small'
                onClick={(e) => handleDocClick(e, cd.document)}
                sx={{ bgcolor: '#DFF3D2', color: '#575757', '& .MuiChip-label': { px: '1rem' } }}
              />
            ))}
          </Box>
        </Box>
        {!isAssessment && <Box
          component='section'
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: '1.25rem',
            borderBottom: '1px solid #DBE3EF',
            mt: '0.5rem',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ color: '#575757', fontWeight: 500 }}>Questionnaires</Typography>
            {user && (user.type === 'vendor_admin' || user.type === 'tpn_admin') && <>
            <LoadingButton
              variant='contained'
              startIcon={<Add />}
              size='small'
              loadingPosition='start'
              aria-controls={isWorkflowMenuOpen ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={isWorkflowMenuOpen ? 'true' : undefined}
              loading={isCreatingWorkflow}
              onClick={handleWorkflowClick}
            >
              New Questionnaire
            </LoadingButton>
            <Menu
              id='basic-menu'
              anchorEl={workflowMenuAnchorEl}
              open={isWorkflowMenuOpen}
              onClose={handleWorkflowMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
            {canStartFiveDotThree ? renderMenuItem('tpn_best_practices_5.3') : renderMenuItem('tpn_best_practices_5.2')}
            </Menu></>}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem', mt: '1rem' }}>
            {wfList?.length === 0 && <Typography>-</Typography>}
            {wfList?.map((workflow, i) => {
              return (
                <Grid container key={workflow.id}>
                  {i !== 0 && <Divider />}
                  <Grid item xs={2}>
                    <GetBadge
                      isActive={company.isPublished}
                      goldBadgeLabel={fetchGoldBadgeLabel(workflow.badges as Badge[], workflowLabels(workflow.workflowType))}
                      blueBadgeLabel={fetchBlueBadgeLabel(workflow.badges as Badge[], workflowLabels(workflow.workflowType))}
                      assessmentSurvey={findAssessmentSurvey(workflow)}
                      assessor={workflow?.assignedAssessor as User | undefined}
                      certification={companyCerts?.at(-1)}
                      company={company.id}
                    />
                  </Grid>
                  <Grid item xs={canDeactivate(workflow) ? 5 : 6}>
                    <Typography sx={{ color: '#848484' }}>{workflowLabels(workflow.workflowType)}</Typography>
                    <Box sx={{ color: '#1876D2', fontWeight: 700 }}>
                      <WorkflowStatus
                        workflow={workflow}
                        company={company}
                        survey={findSurvey(workflow)}
                        assessmentSurvey={findAssessmentSurvey(workflow)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={4}>
                    <WorkflowButtons
                      company={company}
                      workflow={workflow}
                      survey={findSurvey(workflow)}
                      assessmentSurvey={findAssessmentSurvey(workflow)}
                    />
                  </Grid>
                  {canDeactivate(workflow) && (
                    <Grid item xs={1}>
                      <IconButton onClick={() => setwfToDeactivate(workflow)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  )}
                  {workflow.assessment && workflow.isActive && findAssessmentSurvey(workflow) && <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={5}>
                      <Stack spacing={.5} justifyContent='flex-start' alignItems='flex-start' ml='-16px' mt='8px'>
                        <Typography sx={{ fontWeight: 'bold', ml: '16px' }}>Scope</Typography>
                        <FormGroup>
                          <FormControlLabel
                            labelPlacement='start'
                            disabled={user?.type !== 'tpn_admin'}
                            control={<Switch defaultChecked={findAssessmentSurvey(workflow)?.isOnPremise} onChange={(e, v) => updateAssSur({ assSur: findAssessmentSurvey(workflow), key: 'isOnPremise', value: v })} />}
                            label={workflow?.site ? 'Site' : 'On-Prem'}
                          />
                          <FormControlLabel
                            labelPlacement='start'
                            disabled={user?.type !== 'tpn_admin'}
                            control={<Switch defaultChecked={findAssessmentSurvey(workflow)?.isCloud} onChange={(e, v) => updateAssSur({ assSur: findAssessmentSurvey(workflow), key: 'isCloud', value: v })} />}
                            label='Cloud'
                          />
                        </FormGroup>
                      </Stack>
                    </Grid>
                    <Grid item xs={5}>
                      <Stack spacing={.5} justifyContent='flex-start' alignItems='flex-start' ml='-16px' mt='8px'>
                      <Typography sx={{ fontWeight: 'bold', ml: '16px' }}>Type</Typography>
                        <FormGroup>
                          <FormControlLabel
                            labelPlacement='start'
                            disabled={user?.type !== 'tpn_admin'}
                            control={<Switch defaultChecked={findAssessmentSurvey(workflow)?.isInPerson} onChange={(e, v) => updateAssSur({ assSur: findAssessmentSurvey(workflow), key: 'isInPerson', value: v })} />}
                            label='On Site'
                          />
                        </FormGroup>
                      </Stack>
                    </Grid>
                  </>}
                </Grid>
              );
            })}
          </Box>
        </Box>}
        <Box component='section' sx={{ display: 'flex', flexDirection: 'column', p: '1.25rem' }}>
          <Typography sx={{ color: '#575757', fontWeight: 500 }}>Certificates</Typography>
          {!company?.companyCertifications ||
            (company?.companyCertifications.length === 0 && <Typography>-</Typography>)}
          {company.companyCertifications &&
            company.companyCertifications.map((companyCert) => {
              return (
                <Box key={companyCert.id}>
                  {applicationVersions(companyCert)?.map((av: ApplicationVersion) => {
                    if ((av.version as Version).id !== version?.id) {
                      return null;
                    }

                    return (
                      <Box
                        key={(av.version as Version).id}
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          mb: '0.5rem',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography
                            component={Link}
                            to={companyCert.document}
                            target='_blank'
                            rel='noreferrer'
                            sx={{ color: '#848484' }}
                          >
                            {companyCert.certification.title}
                          </Typography>
                          <Box sx={{ display: 'flex', columnGap: '0.25rem', alignItems: 'center' }}>
                            <Typography sx={{ color: '#DF8063', fontSize: '0.9rem' }}>
                              {moment(companyCert.expirationDate).format('MM/DD/YYYY')}
                            </Typography>
                            <Tooltip title='Expiry Date'>
                              <AccessTimeOutlined sx={{ color: '#808080', fontSize: '0.9rem' }} />
                            </Tooltip>
                          </Box>
                        </Box>
                        <Tooltip title={companyCert.adminApproved ? 'Accepted' : 'Rejected'}>
                          {companyCert.adminApproved ? (
                            <CheckCircle sx={{ color: '#7DBF29' }} />
                          ) : (
                            <Cancel sx={{ color: '#DF8063' }} />
                          )}
                        </Tooltip>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
        </Box>
        <AssessmentForm
          modalOpen={isAssessmentModalOpen}
          company={company}
          closeCallback={() => setIsAssessmentModalOpen(false)}
        />
        <StandardDialog
          title='Deactivate Workflow'
          isOpen={!!wfToDeactivate}
          handleClose={() => setwfToDeactivate(undefined)}
        >
          <Typography>Are you sure you wish to deactivate this workflow?</Typography>
          <StandardDialogActions>
            <Button variant='outlined' onClick={() => setwfToDeactivate(undefined)}>
              Cancel
            </Button>
            <Button variant='contained' color='error' onClick={() => deactivateWorkflow()}>
              Deactivate
            </Button>
          </StandardDialogActions>
        </StandardDialog>
      </Drawer>
    </>
  );
};

export default VersionSidebar;
